<template>
  <v-card class="pa-3 mt-2 custom-card-bg-0" flat>
    <div
      v-if="!currentUser.organization"
      class="d-flex flex-column align-center justify-center"
      style="width: 100%; height: 300px"
    >
      <div class="pb-5">Your are not in an Organization. Please create your Organization to manage user member</div>
      <v-btn class="mt-5" color="primary" @click="() => $refs.organizationDialog.showCreate()">
        Create Organization
      </v-btn>
    </div>
    <v-form v-else ref="formData" v-model="valid" class="multi-col-validation" @submit.prevent="update">
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.name"
              :readonly="currentUser.role !== ADMIN"
              :rules="[rules.feildRequired]"
              autofocus
              dense
              label="Name"
              outlined
              placeholder="Your Organiztion name"
              prepend-inner-icon="icon-edit_square"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.email"
              :readonly="currentUser.role !== ADMIN"
              :rules="[rules.emailRules]"
              autofocus
              class="mt-2"
              dense
              label="Email"
              outlined
              placeholder="Your Organiztion Email"
              prepend-inner-icon="icon-message"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.phone"
              :readonly="currentUser.role !== ADMIN"
              autofocus
              class="mt-1"
              dense
              label="Phone"
              outlined
              placeholder="Your Organiztion Phone"
              prepend-inner-icon="icon-call"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="form.address"
              :readonly="currentUser.role !== ADMIN"
              autofocus
              class="mt-1"
              dense
              outlined
              placeholder="Address"
              prepend-inner-icon="icon-location"
              rows="2"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-btn :disabled="currentUser.role !== ADMIN" class="me-3 mt-3" color="primary" type="submit">
          Save changes
        </v-btn>
      </v-card-text>
      <div class="ml-4" style="color: gray">Only admin can update Organization</div>
    </v-form>
    <CreateOrganization ref="organizationDialog" />
  </v-card>
</template>

<script>
import { mapState } from '@/store/ults'
import CreateOrganization from './CreateOrganization.vue'
import { updateOrganization } from '@/api/organization'
import { checkToken } from '@/api/auth-api'
import Store from '@/store'
import { ADMIN } from '@/constants/role'

export default {
  components: { CreateOrganization },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data: () => ({
    form: {},
    ADMIN,
    valid: false,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
      emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
    },
  }),
  mounted() {
    this.form = this.currentUser?.organization ? { ...this.currentUser.organization } : {}
  },
  methods: {
    async updateMe() {
      try {
        await checkToken()
        let result = await checkToken()
        Store.commit('auth/SET_USER', result.data)
      } catch (error) {
        window.location.reload()
      }
    },
    async update() {
      this.$refs.formData.validate()
      if (!this.valid) return
      await updateOrganization(this.form.uuid, this.form)
      this.updateMe()
    },
  },
}
</script>
