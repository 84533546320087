<template>
  <v-card class="pa-3 mt-2 custom-card-bg-0 data-notification" flat>
    <v-card-title>Register alert for the following events:</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <div class="card-bar">
            <v-card-title class="d-flex justify-space-between flex-row">
              <span style="width: 40%;">Events</span>
              <v-select dense v-model="selectedProject" :items="listProjects" item-text="name" item-value="uuid"
                label="Project" style="width: 60%;" outlined hide-details>
              </v-select>
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item-group v-model="selectedEvent">
                  <v-list-item v-for="(event, i) in listEvents" :key="i">
                    <v-list-item-icon>
                      <v-img v-if="event.icon.includes('.svg')" width="18px"
                        :src="require(`@/assets/svg/${event.icon}`)" />
                      <v-icon v-else>{{ event.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="event.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>

            <FormRegisterAlert ref="formRegisterAlert" :listAOIs="listAOIsUnregistered"
              @registerAlert="applyRegister" />
          </div>
        </v-col>
        <v-col cols="8">
          <div class="card-bar">
            <v-card-title>
              <span>Registered</span>
              <v-spacer></v-spacer>
              <v-btn class="text-none mr-2" color="primary" small @click.stop="openDialogRegisterAlert()">
                <v-icon size="20" class="mr-1">mdi-alert-plus-outline</v-icon>
                Add AOIs
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="search" style="width: 100%;" append-icon="mdi-magnify" dense hide-details
                label="Search AOIs" outlined color="primary"></v-text-field>
              <div class="list-datas">
                <v-progress-linear
                  indeterminate
                  color="primary secondary"
                  v-if="loading"
                ></v-progress-linear>
                <v-list v-if="listAOIsRegistered.length > 0">
                  <v-list-item-group v-model="selectedAoi">
                    <v-list-item v-for="(aoi, i) in listAOIsRegistered" :key="i" class="handle-actions">
                      <v-list-item-icon>
                        <v-img  :src="aoi.thumbnail_url" width="28"/>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="aoi.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="actions" :class="{ 'display-actions': loadingBtn == aoi.uuid}">
                        <v-btn icon :loading="loadingBtn == aoi.uuid" small color="error">
                          <v-icon @click="removeFromListRegistered(aoi)">icon-trash</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <div v-else class="text-center"><span>No AOI registered.</span></div>
              </div>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from '@/store/ults'
import { cloneDeep } from 'lodash'
import FormRegisterAlert from './FormRegisterAlert.vue'

export default {
  components: {
    FormRegisterAlert
  },
  data() {
    return {
      loadingBtn: null,
      loading: false,
      search: '',
      chooseEvent: '',
      listEvents: [
        { name: 'Forest Fire', value: 'forest_fire_alert', icon: 'mdi-fire' },
        { name: 'Earthquake', value: 'earthquake_alert', icon: 'earthquake.svg' },
      ],
      selectedAoi: null,
    }
  },
  computed: {
    ...mapState('AOI', ['AOIs']),
    selectedEvent: {
      get() {
        return this.$store.getters['notification/selectedEvent']
      },
      set(value) {
        this.$store.commit('notification/SET_STATE_PROPERTY', { property: 'selectedEvent', value })
      }
    },
    selectedProject: {
      get() {
        return this.$store.getters['project/currentProjectId']
      },
      set(value) {
        this.$store.commit('project/SET_CURRENT_PROJECT_ID', value)
      }
    },
    listAOIs() {
      return this.$store.getters['AOI/listAOIs']
    },
    listProjects() {
      return this.$store.getters['project/projects']
    },
    listAOIsRegistered() {
      return this.AOIs.filter(aoi => aoi.name.includes(this.search) && this.checkRegisteredAOI(aoi))
    },
    listAOIsUnregistered() {
      let listAOIsUnregistered = []
      this.AOIs.forEach(val => {
        val.color = undefined
        val.display = false
        if (!this.checkRegisteredAOI(val)) {
          if (val.group) {
            let group = listAOIsUnregistered.find(item => item.name === val.group && item.items)
            if (group) group.items.push(val)
            else listAOIsUnregistered.push({ name: val.group, items: [val] })
          } else listAOIsUnregistered.push(val)
        }
      })
      return listAOIsUnregistered
    }
  },
  methods: {
    checkRegisteredAOI(aoi) {
      return aoi?.alert_setting && aoi.alert_setting[this.chooseEvent] == true
    },
    async applyRegister(data) {
      this.loading = true
      data = {
        projectId: this.selectedProject,
        payload: data
      }
      await this.$store.dispatch('AOI/registerAlert', data)
      this.loading = false
    },
    openDialogRegisterAlert() {
      this.$refs.formRegisterAlert.open(this.chooseEvent)
    },
    async removeFromListRegistered(aoi) {
      this.loadingBtn = aoi.uuid
      const data = {
        projectId: this.selectedProject,
        id: aoi.uuid,
        payload: {
          event: this.chooseEvent
        }
      }
      await this.$store.dispatch('AOI/unregisterAlert', data)
      this.loadingBtn = null
    },
    async getListAOIs() {
      this.loading = true
      await this.$store.dispatch('AOI/getListAOI', { projectId: this.selectedProject })
      this.loading = false
    }
  },
  watch: {
    listAOIs: {
      async handler(val) {
        // trigger re-render
        const currentSelected = cloneDeep(this.selectedEvent)
        this.selectedEvent = -1
        this.selectedEvent = currentSelected
      },
      deep: true
    },
    selectedProject: {
      async handler(val) {
        await this.getListAOIs()
      },
      deep: true
    },
    selectedEvent: {
      async handler(val) {
        if (val !== null && val !== undefined) {
          this.chooseEvent = this.listEvents[val].value
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.loading = true
    if (this.selectedEvent == null) this.selectedEvent = 0
    if (this.listProjects.length === 0) {
      const params = { per_page: 'all', sort: '-starred,name' }
      await this.$store.dispatch('project/getListProjects', params)
    }
    if (!this.selectedProject) {
      this.listProjects.length === 0 ? this.selectedProject = null : this.selectedProject = this.listProjects[0].uuid
    } else {
      await this.getListAOIs()
    }
    this.loading = false
  }
}
</script>
<style scoped lang="scss">
::v-deep .theme--dark.v-list {
  background-color: transparent !important;
}
.card-bar {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 600px;
  overflow-y: hidden;

  .list-datas {
    height: 450px;
    margin-top: 20px;
    overflow-y: auto;

    .handle-actions {
      .actions {
        display: none;
        &.display-actions {
          display: block;
        }
      }

      &:hover {
        .actions {
          display: block;
        }
      }
    }
  }
}
</style>
