<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title>
        Register alert for {{ event.name }} event
      </v-card-title>

      <v-card-text>
        <v-form ref="formData" @submit.prevent>
          <v-select2
            v-model="selectedAOIs"
            :items="listAOIs"
            item-text="name"
            item-value="uuid"
            label="Choose AOIs"
            outlined
            multiple
            :rules="[rules.aoiRequired]"
            chips
            small-chips
            return-object
          ></v-select2>
        </v-form>
        <div class="list-selected">
          <div class="items-selected" v-if="selectedAOIs.length > 0">
            <v-list>
              <v-list-item-group>
                <v-list-item v-for="(aoi, ind) in selectedAOIs" :key="ind">
                  <v-list-item-content>
                    {{ aoi.name }}
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click="removeFromList(aoi)" small>
                      <v-icon color="error">icon-trash</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" class="text-none" rounded color="error">
          Cancel
        </v-btn>
        <v-btn color="primary" rounded :disabled="!(selectedAOIs.length > 0)" @click="registerAlert">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import VSelect2 from '@/components/Select2/Select2.vue'

export default {
  components: {
    VSelect2
  },
  props: {
    listAOIs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rules: {
        aoiRequired: value => !!value || 'AOI is required',
      },
      dialog: false,
      event: {},
      selectedAOIs: []
    }
  },
  methods: {
    removeFromList(aoi) {
      this.selectedAOIs = this.selectedAOIs.filter(item => item.uuid !== aoi.uuid)
    },
    open(event) {
      this.event = event
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    registerAlert() {
      if (this.$refs.formData.validate()) {
        const data = {
          event: this.event,
          aoi_ids: this.selectedAOIs.map(aoi => aoi.uuid)
        }
        this.$emit('registerAlert', data)
        this.selectedAOIs = []
        this.dialog = false
      }
    }
  },
  watch: {
    listAOIs: {
      handler() {
        this.selectedAOIs = []
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.list-selected {
  border: 1px solid;
  border-radius: 4px;
  margin-top: 10px;
  height: 300px;
  overflow-y: auto;
}
</style>

