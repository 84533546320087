<template>
  <v-card class="mt-5 custom-card-bg-0" flat>
    <v-row>
      <v-col cols="12" md="6" sm="8">
        <v-form ref="formData" v-model="valid" @submit.prevent="update">
          <div class="px-3">
            <v-card-text class="pt-5">
              <input type="text" name="email" value="..." autocomplete="username email" hidden>
              <v-text-field v-model="current_password"
                            :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                            :rules="[rules.fieldRequired]" :type="isCurrentPasswordVisible ? 'text' : 'password'" dense
                            label="Current Password" outlined placeholder="********"
                            @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                            autocomplete="current-password"></v-text-field>

              <v-text-field v-model="new_password"
                            :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                            :rules="[rules.fieldRequired]" :type="isNewPasswordVisible ? 'text' : 'password'" dense
                            hint="Make sure it's at least 8 characters." label="New Password" outlined persistent-hint
                            placeholder="********" @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                            autocomplete="new-password"></v-text-field>

              <v-text-field v-model="new_password_confirmation"
                            :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                            :rules="[rules.fieldRequired]" :type="isCPasswordVisible ? 'text' : 'password'" class="mt-3" dense
                            label="Confirm New Password" outlined placeholder="********"
                            @click:append="isCPasswordVisible = !isCPasswordVisible" autocomplete="new-password"></v-text-field>
            </v-card-text>
          </div>

          <div class="pa-3">
            <v-card-text>
              <v-btn type="submit" class="me-3 mt-3" color="primary">
                Save changes
              </v-btn>
            </v-card-text>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline, mdiKeyOutline, mdiLockOpenOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { changePassword } from '@/api/user'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)

    return {
      current_password: null,
      new_password: null,
      new_password_confirmation: null,
      rules: {
        fieldRequired: value => !!value || 'Field is required',
        emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
      },
      valid: true,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      isCPasswordVisible,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  methods: {
    async update() {
      this.$refs.formData.validate()
      if (!this.valid) return
      try {
        await changePassword({
          new_password: this.new_password,
          current_password: this.current_password,
          new_password_confirmation: this.new_password_confirmation,
        })
        window.location.reload()
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
