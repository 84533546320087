<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div class="pa-4" :class="{ 'animation-go-back': backPage }"
          style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 12px">
          <v-tabs v-model="tab" show-arrows style="border-radius: 12px">
            <v-tab v-for="tab in tabs" :key="tab.icon" class="ml-3">
              <v-icon class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
            <v-spacer></v-spacer>
            <div class="back-btn">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon small v-on="on" v-bind="attrs" @click="goBack">
                    <v-icon>mdi-arrow-u-left-top</v-icon>
                  </v-btn>
                </template>
                <span>Back to previous page</span>
              </v-tooltip>
            </div>
          </v-tabs>

          <v-tabs-items v-model="tab" class="custom-card-bg-0">
            <v-tab-item>
              <account-settings-account></account-settings-account>
            </v-tab-item>

            <v-tab-item>
              <account-settings-security></account-settings-security>
            </v-tab-item>

            <v-tab-item>
              <account-settings-notification></account-settings-notification>
            </v-tab-item>

            <v-tab-item v-if="currentUser.organization">
              <account-settings-info></account-settings-info>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import { mapState } from '@/store/ults'
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
import AccountSettingsNotification from './notification/AccountSettingsNotification.vue'
import AccountSettingsInfo from './AccountSettingsInfo.vue'

export default {
  name: 'User',
  components: {
    AppBarUserMenu,
    ThemeSwitcher,
    Message,
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsNotification,
    AccountSettingsInfo,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    tab: {
      get() {
        return this.$store.getters['auth/currentTab']
      },
      set(val) {
        this.$store.commit('auth/SET_CURRENT_TAB', val)
      }
    }
  },
  data() {
    return {
      backPage: false,
      tabs: [
        { title: 'Account', icon: 'icon-user' },
        { title: 'Security', icon: 'icon-lock' },
        { title: 'Notification', icon: 'icon-notification' },
        { title: 'Organization', icon: 'icon-report' },
      ]
    }
  },
  methods: {
    goBack() {
      this.backPage = true
      setTimeout(() => {
        this.$router.go(-1)
        this.backPage = false
      }, 200);
    }
  },
  mounted() {
    if (!this.currentUser.organization) {
      this.tabs = [
        { title: 'Account', icon: 'icon-user' },
        { title: 'Security', icon: 'icon-lock' },
        { title: 'Notification', icon: 'icon-notification' },
      ]
    }
  },
}
</script>

<style scoped>
::v-deep .v-tabs:not(.v-tabs--vertical) {
  z-index: 0 !important;
}

.animation-go-back {
  animation: slide-right-to-left 0.3s;
}

@keyframes slide-right-to-left {
  0% {
    transform: translateX(-0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.back-btn {
  display: flex;
  align-items: center;
  margin-right: 10px;
  height: 48px;
}

.tree-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.tree-right {
  position: absolute;
  bottom: -30px;
  right: -80px;
}
</style>
