<template>
  <v-card class="pa-3 mt-2 custom-card-bg-0" flat>
    <v-card-text class="d-flex">
      <v-avatar class="mr-6" rounded size="150">
        <img :src="currentUser.avatar || AvatarImg" alt="avatar profile"/>
      </v-avatar>
      <input ref="refInputEl" :hidden="true" accept=".jpeg,.png,.jpg,GIF" type="file" />
      <!-- upload photo -->
      <div class="ml-3">
        <h2>{{ currentUser.name }}</h2>
        <div v-if="currentUser.role !== SYSADMIN" class="mt-1">
          Organization: <span v-if="currentUser.organization" class="pl-1">{{ currentUser.organization.name }}</span>
          <v-btn v-else class="ml-3" color="primary" small @click="() => $refs.organizationDialog.showCreate()">
            Create organization
          </v-btn>
          <div v-if="!currentUser.organization" class="mt-1" style="color: gray; font-size: 13px;">
            Please create your organization information
          </div>
        </div>
        <div class="mt-2">Join at: {{ currentUser.created_at }}</div>

        <div class="mt-3">
          <v-chip class="" color="primary" medium>
            {{ currentUser.role }}
          </v-chip>
        </div>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form ref="formData" v-model="valid" class="multi-col-validation mt-6" @submit.prevent="update">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="currentUser.name"
              :rules="[rules.fieldRequired]"
              dense
              label="Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="currentUser.email"
              :rules="[rules.emailRules]"
              dense
              label="E-mail"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn type="submit" class="me-3 mt-4" color="primary">
              Save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <CreateOrganization ref="organizationDialog" />
  </v-card>
</template>

<script>
import { mapState } from '@/store/ults'
import AvatarImg from '@/assets/images/user/avatar.png'
import CreateOrganization from './CreateOrganization.vue'
import { updateAccount } from '@/api/user'
import { SYSADMIN } from '@/constants/role'

export default {
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  components: { CreateOrganization },
  setup() {
    return {
      SYSADMIN,
      AvatarImg,
      valid: true,
      rules: {
        fieldRequired: value => !!value || 'Field is required',
        emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
      },
    }
  },
  methods: {
    async update() {
      this.$refs.formData.validate()
      if (!this.valid) return
      const { name, email } = this.currentUser
      await updateAccount({ name, email })
    },
  },
}
</script>
